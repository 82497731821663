var callback = function () {
    $('.item-skills').each(function () {
        newWidth = $(this).parent().width() * $(this).data('percent');
        $(this).width(0);
        $(this).animate({
            width: newWidth
        }, 1000);
    });
    $('.icons-red').each(function () {
        height = $(this).height();
        $(this).animate({
            height: 14
        }, 2000);
    });

    $('#age').html(GetAge("1988-06-25"));
};
$(document).ready(callback);

function GetAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age + 1;
}
var resize;
window.onresize = function () {
    clearTimeout(resize);
    resize = setTimeout(function () {
        callback();
    }, 100);
};